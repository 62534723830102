/* eslint-disable complexity */
import React from "react";
import { Helmet } from "react-helmet";
import { Button, Carousel, CardGroup, Card, ListGroup } from "react-bootstrap";
import { IoMdCheckmark } from "react-icons/io";
import ReactGA from "react-ga";
import Fade from "react-reveal/Fade";
import GenericGallery from "../../../components/GenericGallery";
import Styles from "./styles/Global";
import * as Pages from "../../../utils/Pages";
import domRefs from "../../../domrefs";
import WebContext from "../../../utils/WebContext";
import GenericDynamicContent from "../../../components/GenericDynamicContent";
import { apiFetchDynamicContent } from "../../../api";

export default class Home extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { content: [] };
        this.state.animateCarousel = false;
        this.state.showFAB = false;
    }

    componentDidMount() {
        this.props.loadUIContext();
        setInterval( () => {
            this.setState( { animateCarousel: !this.state.animateCarousel } );
        }, 10000 );
        apiFetchDynamicContent( "Page", "home" )
            .then( result => {
                this.setState( { content: result.dynamicContent } );
            } );
    }

    render() {
        const page = Pages.getPage( "home" );
        let isLandscape = true;
        if ( this.props.UIContext.loaded && this.props.UIContext.windowWidth < this.props.UIContext.windowHeight ) {
            isLandscape = false;
        }
        const jobsDimensions = { width: 0, height: 0 };
        const lang = this.context.language;
        if ( this.props.UIContext.loaded ) {
            jobsDimensions.width = this.props.UIContext.windowWidth - 40;
            if ( isLandscape ) {
                jobsDimensions.width = this.props.UIContext.windowWidth / 2;
            }
            jobsDimensions.height = ( jobsDimensions.width / 16 ) * 9;
        }
        return (
            <div className={ Pages.getPageClassNames( page ) }>
                <Helmet>
                    <title>{ page.seoTitle }</title>
                    <meta name="description" content={ page.seoDescription } />
                    <meta name="keywords" content={ page.seoKeywords } />
                    <meta name="author" content={ page.seoAuthor } />
                    <meta httpEquiv="content-Language" content={ lang } />
                    <meta name="robots" content="all" />
                    <meta name="rating" content="General" />
                    <meta name="language" content={ lang } />
                    <meta name="DC.title" content={ page.seoTitle } />
                    <meta name="DC.description" content={ page.seoDescription } />
                    <meta property="og:title" content={ page.seoTitle } />
                    <meta property="og:description" content={ page.seoDescription } />
                    <meta property="og:url" content={ page.url } />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content={ Pages.company.name } />
                    <meta property="og:image" content={ `${ Pages.company.baseURL }/static/og_image_default.png` } />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:description" content={ page.seoDescription } />
                    <meta name="twitter:title" content={ page.seoTitle } />
                </Helmet>
                <GenericDynamicContent items={ this.state.content } />
                <h2 style={ Styles.Home.SectionTitle }>{ Pages.text( lang, "home.section2.text1" ) }</h2>
                <h3 style={ Styles.Home.SectionSubTitle }><div dangerouslySetInnerHTML={ { __html: Pages.text( lang, "home.section2.text2" ) } } /></h3>
                <Carousel
                    fade
                    style={ {
                        width: jobsDimensions.width,
                        height: jobsDimensions.height,
                        margin: "auto"
                    } }
                    indicators={ false }
                    interval={ null }
                >
                    <Carousel.Item>
                        <iframe
                            style={ { width: jobsDimensions.width, height: jobsDimensions.height } }
                            src="https://player.vimeo.com/video/885922949"
                            title="video"
                            frameBorder="0"
                            allowFullScreen
                            allow="autoplay; fullscreen"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <iframe
                            style={ { width: jobsDimensions.width, height: jobsDimensions.height } }
                            src="https://player.vimeo.com/video/647238470"
                            title="video"
                            frameBorder="0"
                            allowFullScreen
                            allow="autoplay; fullscreen"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <iframe
                            style={ { width: jobsDimensions.width, height: jobsDimensions.height } }
                            src="https://player.vimeo.com/video/357440612"
                            title="video"
                            frameBorder="0"
                            allowFullScreen
                            allow="autoplay; fullscreen"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <iframe
                            style={ { width: jobsDimensions.width, height: jobsDimensions.height } }
                            src="https://player.vimeo.com/video/352479140"
                            title="video"
                            frameBorder="0"
                            allowFullScreen
                            allow="autoplay; fullscreen"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <iframe
                            style={ { width: jobsDimensions.width, height: jobsDimensions.height } }
                            src="https://player.vimeo.com/video/309361416"
                            title="video"
                            frameBorder="0"
                            allowFullScreen
                            allow="autoplay; fullscreen"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <iframe
                            style={ { width: jobsDimensions.width, height: jobsDimensions.height } }
                            src="https://player.vimeo.com/video/309357922"
                            title="video"
                            frameBorder="0"
                            allowFullScreen
                            allow="autoplay; fullscreen"
                        />
                    </Carousel.Item>
                </Carousel>
                <Fade bottom>
                    <div style={ { textAlign: "center" } }>
                        <Button variant="link" href="https://www.instagram.com/jorgealarcon.es" target="_blank" onClick={ () => ReactGA.event( { category: "Social", action: "Jobs Instagram Icon Click" } ) }>
                            <img
                                src="/static/social-icons-instagram.png"
                                alt={ page.seoDefaultAlt }
                                style={ Styles.Home.CarouselSocialIcon }
                            />
                        </Button>
                        <Button variant="link" href="https://www.facebook.com/jorgealarconphotography/" target="_blank" onClick={ () => ReactGA.event( { category: "Social", action: "Jobs Facebook Icon Click" } ) }>
                            <img
                                src="/static/social-icons-fb.png"
                                alt={ page.seoDefaultAlt }
                                style={ Styles.Home.CarouselSocialIcon }
                            />
                        </Button>
                        <Button variant="link" href="https://vimeo.com/jorgealarconvimeo" target="_blank" onClick={ () => ReactGA.event( { category: "Social", action: "Jobs Vimeo Icon Click" } ) }>
                            <img
                                src="/static/social-icons-vimeo.png"
                                alt={ page.seoDefaultAlt }
                                style={ Styles.Home.CarouselSocialIcon }
                            />
                        </Button>
                    </div>
                </Fade>
                { page.gallery.id ?
                    <div style={ Styles.Home.SectionContentContainer }>
                        <GenericGallery id={ page.gallery.id } />
                    </div> :
                    []
                }
                <h2 style={ Styles.Home.SectionTitle }>{ Pages.text( lang, "home.section3.text1" ) }</h2>
                <h3 style={ Styles.Home.SectionSubTitle }><div dangerouslySetInnerHTML={ { __html: Pages.text( lang, "home.section3.text2" ) } } /></h3>
                <CardGroup style={ Styles.Home.CardDeck }>
                    <Card style={ Styles.Home.priceCard }>
                        <Fade bottom>
                            <Card.Body>
                                <Card.Title style={ Styles.Home.priceCardTitle }>{ Pages.text( lang, "home.pricecard1.text1" ) }</Card.Title>
                                <Card.Text>{ Pages.text( lang, "home.pricecard1.text2" ) }</Card.Text>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } /> { Pages.text( lang, "home.pricecard1.text3" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } /> { Pages.text( lang, "home.pricecard1.text4" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } /> { Pages.text( lang, "home.pricecard1.text5" ) }</ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer style={ Styles.Home.priceCardFooter }>
                                <Button
                                    variant="pw-primary"
                                    onClick={ () => {
                                        window.scrollTo( 0, domRefs.contactRef.current.offsetTop );
                                        ReactGA.event( { category: "Prospect", action: "Oferta Foto Click" } );
                                    } }
                                >
                                    { Pages.text( lang, "home.pricecard.requestquote" ) }
                                </Button>
                            </Card.Footer>
                        </Fade>
                    </Card>
                    {/* <Card style={ Styles.Home.priceCard }>
                        <Fade bottom>
                            <Card.Img variant="top" src="/static/promo-card-video-promocional.png" alt={ page.seoDefaultAlt } />
                            <Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard2.text3" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard2.text4" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard2.text5" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard2.text6" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard2.text7" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />Entrega del producto en 3 formatos diferentes: Página web y Redes Sociales</ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer style={ Styles.Home.priceCardFooter }>
                                <Button
                                    onClick={ () => {
                                        window.scrollTo( 0, domRefs.contactRef.current.offsetTop );
                                        ReactGA.event( { category: "Prospect", action: "Oferta Video Click" } );
                                    } }
                                    variant="pw-primary"
                                >
                                    { Pages.text( lang, "home.pricecard.requestquote" ) }
                                </Button>
                            </Card.Footer>
                        </Fade>
                    </Card> */}
                    <Card style={ Styles.Home.priceCard }>
                        <Fade bottom>
                            <Card.Body>
                                <Card.Title style={ Styles.Home.priceCardTitle }>{ Pages.text( lang, "home.pricecard2.text1" ) }</Card.Title>
                                <Card.Text>{ Pages.text( lang, "home.pricecard2.text2" ) }</Card.Text>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard2.text3" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard2.text4" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard2.text5" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard2.text6" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard2.text7" ) }</ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer style={ Styles.Home.priceCardFooter }>
                                <Button
                                    onClick={ () => {
                                        window.scrollTo( 0, domRefs.contactRef.current.offsetTop );
                                        ReactGA.event( { category: "Prospect", action: "Oferta Video Click" } );
                                    } }
                                    variant="pw-primary"
                                >
                                    { Pages.text( lang, "home.pricecard.requestquote" ) }
                                </Button>
                            </Card.Footer>
                        </Fade>
                    </Card>
                    {/* <Card style={ Styles.Home.priceCard }>
                        <Fade bottom>
                            <Card.Body>
                                <Card.Title style={ Styles.Home.priceCardTitle }>{ Pages.text( lang, "home.pricecard3.text1" ) }</Card.Title>
                                <Card.Text>{ Pages.text( lang, "home.pricecard3.text2" ) }</Card.Text>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard3.text3" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard3.text4" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard3.text5" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard3.text6" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard3.text7" ) }</ListGroup.Item>
                                    <ListGroup.Item><IoMdCheckmark style={ Styles.Home.priceCardPriceCheck } />{ Pages.text( lang, "home.pricecard3.text8" ) }</ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                            <Card.Footer style={ Styles.Home.priceCardFooter }>
                                <Button
                                    variant="pw-primary"
                                    onClick={ () => {
                                        window.scrollTo( 0, domRefs.contactRef.current.offsetTop );
                                        ReactGA.event( { category: "Prospect", action: "Oferta Video y Foto Click" } );
                                    } }
                                >
                                    { Pages.text( lang, "home.pricecard.requestquote" ) }
                                </Button>
                            </Card.Footer>
                        </Fade>
                    </Card> */}
                </CardGroup>
            </div>
        );
    }
}

Home.contextType = WebContext;

// Home.serverFetch = fetchData; // static declaration of data requirements
