export default {
    generic: {
        title: "Algunos de nuestros trabajos",
        subTitle: "Te presentamos solo algunos de nuestros trabajos ... aunque será mucho mejor si vienes a visitarnos!",
        targetRowHeight: 160,
        photos: []
    },
    bodas: {
        title: null,
        subTitle: null,
        targetRowHeight: 500,
        photos: [
            { src: "/static/news-bodas-001.jpg", height: 1, width: 0.666 },
            { src: "/static/news-bodas-002.jpg", height: 1, width: 0.666 },
            { src: "/static/news-bodas-003.jpg", height: 1, width: 0.666 },
            { src: "/static/news-bodas-004.jpg", height: 1, width: 1.5 },
            { src: "/static/news-bodas-005.jpg", height: 1, width: 1.315 },
            { src: "/static/news-bodas-006.jpg", height: 1, width: 1.5 },
            { src: "/static/news-bodas-007.jpg", height: 1, width: 1.436 },
            { src: "/static/news-bodas-008.jpg", height: 1, width: 0.666 },
            { src: "/static/news-bodas-009.jpg", height: 1, width: 0.666 },
            { src: "/static/news-bodas-010.jpg", height: 1, width: 1.5 }
        ]
    }
};
