import { matchPath } from "react-router-dom";
import GenericPage from "../../components/GenericPage";
import GenericNews from "../../components/GenericNews";
import Home from "../../components/Home";
import store from "../../store";

export const ENVIRONMENT = process.env.NODE_ENV === "production" ? "production" : "development";

export const PageComponents = {};
PageComponents.ForCompanies = require( "./components/ForCompanies" ).default;
PageComponents.About = require( "./components/About" ).default;
PageComponents.PrivacyPolicy = require( "./components/PrivacyPolicy" ).default;
PageComponents.CookiesPolicy = require( "./components/CookiesPolicy" ).default;

export const getUrlBase = ( env ) => {
    if ( env === "development" ) {
        return "http://localhost:2048";
    }
    return "https://www.jorgealarcon.es";
};

export const company = {
    name: "Jorge Alarcón",
    legalName: "Jorge Alarcón",
    domain: "jorgealarcon.es",
    lopdFullName: "Jorge Alarcón",
    lopdNIF: "",
    lopdEmail: "info@jorgealarcon.es",
    lopdPostalAddress: "",
    lopdPhone: "600507536",
    facebookPageURL: "https://www.facebook.com/jorgealarconphotography",
    whatsappContactURL: "https://wa.me/34600507536",
    googleFontsTag: "<link href=\"https://fonts.googleapis.com/css?family=Montserrat|Roboto:100,100i,300,300i,400,400i,500,500i&display=swap\" rel=\"stylesheet\" />",
    googleAnalyticsTrackingID: "UA-143522464-3",
    facebookAPPID: "348655195810991",
    contactEmail: "info@jorgealarcon.es",
    managerURLRelative: "/jorgealarcon",
    footerTermsConditions: false,
    facebookPixelId: "243250246995960",
    footerHideFacebook: true,
    baseURL: ENVIRONMENT === "development" ? "http://localhost:2048" : "http://www.jorgealarcon.es",
    smartlookCode: "e8c8579d9eed45a7e3d340384f1a9323e129e24f"
};

export const config = {
    reCaptchaSiteKey: "6LeqmKoUAAAAAPBWBD8aQmVM9XIc4qNQgsjGHdFJ"
};

export const NavBar = {
    topNavBar: {
        enabled: false
    },
    customMenuItems: null
};

export const all = [
    {
        path: "(/|/en/|/es/)",
        relativeUrl: "/",
        relativeUrlEn: "/en",
        url: "https://www.jorgealarcon.es",
        component: Home,
        exact: true,
        id: "home",
        menuText: "Inicio",
        menuHidden: true,
        title: "Inicio",
        subTitle: "Videógrafo y Fotógrafo en Madrid",
        subTitleEn: "Filmmaker & Photographer in Madrid",
        breadcrumb: [],
        seoTitle: "Videógrafo y Fotógrafo en Madrid | Jorge Alarcón",
        seoTitleEn: "Filmmaker & Photographer in Madrid | Jorge Alarcón",
        seoDescription: "Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoDescriptionEn: "Photos and professional Videos for weddings, social networks, events, companies, products and more ... in Madrid",
        seoKeywords: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoAuthor: "Jorge Alarcón: Videógrafo y Fotógrafo en Madrid",
        seoAuthorEn: "Jorge Alarcón: Filmmaker and Photographer in Madrid",
        seoDefaultAlt: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoDefaultAltEn: "Filmmaker and Photograper in Madrid. Photos and professional Videos for weddings, social networks, events, companies, products and more.",
        embeds: [],
        gallery: {},
        navBarCSSPosition: "fixed"
    },
    {
        path: "/video-foto-mallorca/videografo-fotografo-para-empresas",
        relativeUrl: "/video-foto-mallorca/videografo-fotografo-para-empresas",
        url: "https://www.jorgealarcon.es/video-foto-mallorca/videografo-fotografo-para-empresas",
        component: GenericPage,
        childComponentFile: "ForCompanies",
        exact: true,
        id: "forcompanies",
        menuText: "Empresas",
        menuHidden: true,
        title: "Videos y Fotos para empresas",
        subTitle: "Busco constantemente las mejores tomas y fotografías de la única forma que conozco ... con creatividad, pasión y calidad profesional. Te invitamos a conocer algunos de mis trabajos, pero no dudes en contactarme para resolver cualquier duda.",
        breadcrumb: [ "home", "forcompanies" ],
        seoTitle: "Videógrafo y Fotógrafo en Madrid | Jorge Alarcón",
        seoDescription: "Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoKeywords: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoAuthor: "Jorge Alarcón: Videógrafo y Fotógrafo en Madrid",
        seoDefaultAlt: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        embeds: [],
        gallery: {}
    },
    {
        path: "/video-foto-mallorca/videografo-fotografo-de-bodas",
        relativeUrl: "/video-foto-mallorca/videografo-fotografo-de-bodas",
        url: "https://www.jorgealarcon.es/video-foto-mallorca/videografo-fotografo-de-bodas",
        component: GenericPage,
        childComponentFile: "ForCompanies",
        exact: true,
        id: "forweddings",
        menuText: "Bodas",
        menuHidden: true,
        title: "Videos y Fotos de bodas",
        subTitle: "Busco constantemente las mejores tomas y fotografías de la única forma que conozco ... con creatividad, pasión y calidad profesional. Te invitamos a conocer algunos de mis trabajos, pero no dudes en contactarme para resolver cualquier duda.",
        breadcrumb: [ "home", "forweddings" ],
        seoTitle: "Videógrafo y Fotógrafo en Madrid | Jorge Alarcón",
        seoDescription: "Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoKeywords: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoAuthor: "Jorge Alarcón: Videógrafo y Fotógrafo en Madrid",
        seoDefaultAlt: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        embeds: [],
        gallery: {}
    },
    {
        path: "/video-foto-mallorca/videografo-fotografo-redes-sociales",
        relativeUrl: "/video-foto-mallorca/videografo-fotografo-redes-sociales",
        url: "https://www.jorgealarcon.es/video-foto-mallorca/videografo-fotografo-redes-sociales",
        component: GenericPage,
        childComponentFile: "ForCompanies",
        exact: true,
        id: "forsocial",
        menuText: "Redes Sociales",
        menuHidden: true,
        title: "Videos y Fotos para Redes Sociales",
        subTitle: "Busco constantemente las mejores tomas y fotografías de la única forma que conozco ... con creatividad, pasión y calidad profesional. Te invitamos a conocer algunos de mis trabajos, pero no dudes en contactarme para resolver cualquier duda.",
        breadcrumb: [ "home", "forsocial" ],
        seoTitle: "Videógrafo y Fotógrafo en Madrid | Jorge Alarcón",
        seoDescription: "Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoKeywords: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoAuthor: "Jorge Alarcón: Videógrafo y Fotógrafo en Madrid",
        seoDefaultAlt: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        embeds: [],
        gallery: {}
    },
    {
        path: "/video-foto-mallorca/videografo-fotografo-para-eventos",
        relativeUrl: "/video-foto-mallorca/videografo-fotografo-para-eventos",
        url: "https://www.jorgealarcon.es/video-foto-mallorca/videografo-fotografo-para-eventos",
        component: GenericPage,
        childComponentFile: "ForCompanies",
        exact: true,
        id: "forevents",
        menuText: "Eventos",
        menuHidden: true,
        title: "Videos y Fotos para Eventos",
        subTitle: "Busco constantemente las mejores tomas y fotografías de la única forma que conozco ... con creatividad, pasión y calidad profesional. Te invitamos a conocer algunos de mis trabajos, pero no dudes en contactarme para resolver cualquier duda.",
        breadcrumb: [ "home", "forevents" ],
        seoTitle: "Videógrafo y Fotógrafo en Madrid | Jorge Alarcón",
        seoDescription: "Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoKeywords: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoAuthor: "Jorge Alarcón: Videógrafo y Fotógrafo en Madrid",
        seoDefaultAlt: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        embeds: [],
        gallery: {}
    },
    {
        path: "/video-foto-mallorca/videografo-fotografo-de-productos",
        relativeUrl: "/video-foto-mallorca/videografo-fotografo-de-productos",
        url: "https://www.jorgealarcon.es/video-foto-mallorca/videografo-fotografo-de-productos",
        component: GenericPage,
        childComponentFile: "ForCompanies",
        exact: true,
        id: "forproducts",
        menuText: "Productos",
        menuHidden: true,
        title: "Videos y Fotos de Productos",
        subTitle: "Busco constantemente las mejores tomas y fotografías de la única forma que conozco ... con creatividad, pasión y calidad profesional. Te invitamos a conocer algunos de mis trabajos, pero no dudes en contactarme para resolver cualquier duda.",
        breadcrumb: [ "home", "forproducts" ],
        seoTitle: "Videógrafo y Fotógrafo en Madrid | Jorge Alarcón",
        seoDescription: "Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoKeywords: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoAuthor: "Jorge Alarcón: Videógrafo y Fotógrafo en Madrid",
        seoDefaultAlt: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        embeds: [],
        gallery: {}
    },
    {
        path: "/video-foto-mallorca/videografo-fotografo-retratos",
        relativeUrl: "/video-foto-mallorca/videografo-fotografo-retratos",
        url: "https://www.jorgealarcon.es/video-foto-mallorca/videografo-fotografo-retratos",
        component: GenericPage,
        childComponentFile: "ForCompanies",
        exact: true,
        id: "forportraits",
        menuText: "Retratos",
        menuHidden: true,
        title: "Retratos familiares y para empresas",
        subTitle: "Busco constantemente las mejores tomas y fotografías de la única forma que conozco ... con creatividad, pasión y calidad profesional. Te invitamos a conocer algunos de mis trabajos, pero no dudes en contactarme para resolver cualquier duda.",
        breadcrumb: [ "home", "forportraits" ],
        seoTitle: "Videógrafo y Fotógrafo en Madrid | Jorge Alarcón",
        seoDescription: "Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoKeywords: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        seoAuthor: "Jorge Alarcón: Videógrafo y Fotógrafo en Madrid",
        seoDefaultAlt: "Videógrafo y Fotógrafo en Madrid. Fotos y Videos profesionales para bodas, redes sociales, eventos, empresas, productos en Madrid",
        embeds: [],
        gallery: {}
    },
    {
        path: "/video-foto-mallorca/politica-privacidad",
        relativeUrl: "/video-foto-mallorca/politica-privacidad",
        url: "https://www.jorgealarcon.es/video-foto-mallorca/politica-privacidad",
        exact: true,
        component: GenericPage,
        childComponentFile: "PrivacyPolicy",
        id: "privacy-policy",
        menuText: "Política de privacidad",
        menuTextEn: "Privacy Policy",
        menuHidden: true,
        title: "Política de privacidad",
        subTitle: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        breadcrumb: [ "home", "privacy-policy" ],
        seoTitle: "Política de privacidad",
        seoDescription: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        seoKeywords: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        embeds: [],
        gallery: {},
        seoAuthor: "Videógrafo y Fotógrafo en Madrid | Jorge Alarcón",
        seoDefaultAlt: "Videógrafo y Fotógrafo en Madrid | Jorge Alarcón"
    },
    {
        path: "/video-foto-mallorca/politica-cookies",
        relativeUrl: "/video-foto-mallorca/politica-cookies",
        url: "https://www.jorgealarcon.es/video-foto-mallorca/politica-cookies",
        exact: true,
        component: GenericPage,
        childComponentFile: "CookiesPolicy",
        id: "cookies-policy",
        menuText: "Política de Cookies",
        menuTextEn: "Cookies Policy",
        menuHidden: true,
        title: "Política de Cookies",
        subTitle: "",
        breadcrumb: [ "home", "cookies-policy" ],
        seoTitle: "",
        seoDescription: "",
        seoKeywords: "",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "(/mis-novedades.*)",
        relativeUrl: "/mis-novedades",
        url: "https://www.jorgealarcon.es/novedades",
        exact: true,
        component: GenericNews,
        childComponentFile: "News",
        id: "news",
        menuText: "Novedades",
        menuTextEn: "News",
        title: "",
        subTitle: "",
        breadcrumb: [],
        seoTitle: "Novedades acerca de Jorge Alarcón: Videógrafo y fotógrafo profesional en Madrid",
        seoDescription: "Novedades acerca de Jorge Alarcón: Videógrafo y fotógrafo profesional en Madrid. Mis viajes, ofertas, ideas.",
        seoKeywords: "Novedades acerca de Jorge Alarcón: Videógrafo y fotógrafo profesional en Madrid. Mis viajes, ofertas, ideas.",
        embeds: [],
        gallery: {},
        seoAuthor: "Jorge Alarcón: Videógrafo y Fotógrafo en Madrid",
        seoDefaultAlt: "Novedades acerca de Jorge Alarcón: Videógrafo y fotógrafo profesional en Madrid. Mis viajes, ofertas, ideas.",
        navBarCSSPosition: "fixed",
        navBarPagePadding: 75
    }
];

const getPagePropByLang = ( page, lang, prop ) => {
    const langProp = `${ prop }${ lang.charAt( 0 ).toUpperCase() + lang.slice( 1 ) }`;
    if ( typeof page[ langProp ] !== "undefined" ) {
        return page[ langProp ];
    }
    return page[ prop ];
};

const applyPageOverwrites = ( page ) => {
    let result = page;
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            result = { ...page, title: getPagePropByLang( page, lang, "title" ) };
            Object.keys( page ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( page, lang, key );
            } );
        }
    }
    return result;
};

export const applyNewsOverides = ( item ) => {
    const result = { ...item };
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};

export const applyShopOverides = ( item ) => {
    const result = Object.assign( {}, item );
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};

export const getPage = ( id ) => {
    let result = all.find( ( page ) => page.id === id );
    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

const texts = {
    es: {
        "navbar.title": "Videógrafo y Fotógrafo",
        "footer.contact.text1": "Envíame un mensaje",
        "footer.contact.text2": "No dudes en contactarme sin ningún compromiso, puedes usar tanto el formulario rápido de mi web como a través de mis redes sociales",
        "footer.contact.fullname": "Nombre Completo",
        "footer.contact.fullname.description": "Escribe tu nombre completo para poder dirigirnos a ti correctamente.",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "Escribe aquí tu email para que podamos contestar a la brevedad.",
        "footer.contact.phone": "Teléfono",
        "footer.contact.phone.description": "Escribe aquí tu teléfono si prefieres que te contactemos por Whatsapp o una llamada.",
        "footer.contact.message": "Mensaje",
        "footer.contact.privacypolicy": `He leído y acepto la <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Política de privacidad</a>${ company.footerTermsConditions ? `<span> y los <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></span>` : "" }`,
        "footer.contact.sendmessage": "Enviar consulta",
        "footer.contact.orremember": "O recuerde que también puede contactarnos a través de",
        "footer.contact.emailbutton": "Correo electrónico",
        "footer.contact.whatsapp": "Enviar un WhatsApp",
        "footer.contact.visitfacebook": "Visítanos en Facebook",
        "footer.contact.successmessage.text1": "Gracias por ponerte en contacto con nosotros.",
        "footer.contact.successmessage.text2": "Hemos recibido tu mensaje y te responderemos a la brevedad a",
        "footer.contact.successmessage.text3": "En {companyName} nos tomamos muy en serio las consultas de nuestros clientes y en responder profesionalmente. Muchas gracias por su paciencia.",
        "footer.copyright.text1": "Copyright Jorge Alarcón: Videógrafo y Fotógrafo",
        // footer contact form
        "footer.contact.promolima.title": "Reserva tu video promocional a precio promocional",
        "footer.contact.promolima.subtitle": "Completa el siguiente formulario para conseguir tu reserva",
        "footer.contact.promolima.successmessage.title": "Gracias por enviarme tu solicitud.",
        "footer.contact.promolima.successmessage.text1": "He recibido tu mensaje y te responderé a la brevedad.",
        "footer.contact.promolima.successmessage.text2": "Estaré organizando mi agenda de trabajo del 2 de Enero al 6 de Marzo del 2020 en Lima, por lo que me pondré en contacto contigo para planificar tu video promocional.",
        "footer.contact.promolima.phone": "Teléfono",
        "footer.contact.promolima.company": "Razón Social (Opcional)",
        "footer.contact.promolima.ruc": "RUC (Opcional)",
        "footer.contact.promolima.message": "Describe brevemente tu proyecto",
        "footer.contact.promolima.send": "Solicitar Reserva",
        // news
        "news.categories": "Categorías",
        "news.viewmore": "Ver más",
        // customs
        "home.cover.text1": "VIDEOS Y FOTOS PROFESIONALES en Madrid",
        "home.cover.text2": "Los mejores videos y fotos profesionales para empresas, bodas, redes sociales, anuncios y mucho más.",
        "home.section1.text1": "¿Buscas un Videógrafo y Fotógrafo en Madrid?",
        "home.section1.text2": "Capturaré las mejores tomas y fotografías con creatividad, pasión y calidad profesional. Te invito a conocer algunos de mis servicios, pero no dudes en contactarme para resolver cualquier duda.",
        "home.section1.moreinfo": "Más información",
        "home.section1.text3": "Videos promocionales, fotografías de equipos, retratos y videos corporativos",
        "home.section1.text4": "EMPRESAS Y PRODUCTOS",
        "home.section1.item2.text1": "BODAS",
        "home.section1.item2.text2": "Videos y fotos de los mejores momentos de la boda, escenas cinematográficas e inolvidables.",
        "home.section1.item3.text1": "REDES SOCIALES",
        "home.section1.item3.text2": "¿Buscas destacar en redes sociales con contenido de alto impacto desde Madrid?",
        "home.section1.item4.text1": "EVENTOS",
        "home.section1.item4.text2": "Celebraciones, Fiestas, Competiciones y todo tipo de eventos en Madrid",
        "home.section1.item5.text1": "MÚSICA",
        "home.section1.item5.text2": "Video clips, Conciertos y videos especialmente enfocados a la industria de la música.",
        "home.section1.item6.text1": "RETRATOS",
        "home.section1.item6.text2": "Retratos familiares, perfiles profesionales, presentación de equipos de empresas",
        "home.section1.item7.text1": "ESPACIOS GASTRONÓMICOS",
        "home.section1.item7.text2": "Producción de fotos y videos específicos para el sector gastronómico",
        "home.section2.text1": "Trabajos recientes",
        "home.section2.text2": "Algunos de mis videos y set de fotografías profesionales para empresas, bodas, eventos, productos.<br />Estoy en Madrid pero viajar no es un obstáculo para mi.",
        "home.section3.text1": "Ofertas de Video y Fotografía",
        "home.section3.text2": "¿Buscas el mejor precio por un servicio profesional y de calidad? No dudes en enviarme tu consulta.<br />A continuación he preparado 3 planes orientativos, pero no dudes en contactarme para pedir un presupuesto a medida.",
        "home.pricecard1.text1": "Plan fotográfico",
        "home.pricecard1.text2": "Fotos profesionales en Madrid, de su empresa, boda, familia, eventos, amigos, para redes sociales, anuncios, promocionarte en internet o simplemente para ti y tu familia.",
        "home.pricecard1.text3": "Incluye el traslado de equipos donde sea necesario, mar, aire o tierra",
        "home.pricecard1.text4": "Sesión de fotos profesionales",
        "home.pricecard1.text5": "Trabajo de edición y preparación del material para publicar y compartir en redes sociales",
        "home.pricecard2.text1": "Plan Video Profesional",
        "home.pricecard2.text2": "Grabación de un video profesional en Madrid, de su empresa, boda, familia, eventos, amigos, para redes sociales, anuncios, promocionarse en internet ... o simplemente para ti y tu familia.",
        "home.pricecard2.text3": "Diseño del guión junto con el cliente",
        "home.pricecard2.text4": "Plan para la grabación de las tomas",
        "home.pricecard2.text5": "Traslado de los equipos donde sea necesario, mar, aire o tierra",
        "home.pricecard2.text6": "Postproducción audiovisual",
        "home.pricecard2.text7": "Edición y preparación del material para publicar y compartir en redes sociales",
        "home.pricecard3.text1": "Plan Video y Fotografía",
        "home.pricecard3.text2": "Grabación de un video profesional en Madrid, de su empresa, boda, familia, eventos, amigos, para redes sociales, anuncios, promocionarse en internet ... o simplemente para ti y tu familia.",
        "home.pricecard3.text3": "Sesión de fotos profesionales",
        "home.pricecard3.text4": "Diseño del guión del video junto con el cliente",
        "home.pricecard3.text5": "Plan para la grabación de las tomas",
        "home.pricecard3.text6": "Traslado de los equipos donde sea necesario, mar, aire o tierra",
        "home.pricecard3.text7": "Postproducción audiovisual",
        "home.pricecard3.text8": "Entrega de videos listos para compartir",
        "home.pricecard.requestquote": "Pedir Presupuesto",
        "footer.floatingvideo.sendmessage": "Envíame un mensaje"
    },
    en: {
        "navbar.title": "Filmmaker & Photographer",
        "footer.contact.text1": "Any further questions?",
        "footer.contact.text2": "Please do not hesitate to contact us, tell us about your business or project and We will reply as soon as possible.",
        "footer.contact.fullname": "Full name",
        "footer.contact.fullname.description": "Please enter your full name",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "Enter your email to reply as soon as possible.",
        "footer.contact.phone": "Phone",
        "footer.contact.phone.description": "Enter your phone and we can contact you via Whatsapp or a call.",
        "footer.contact.message": "Message",
        "footer.contact.privacypolicy": `I have read and accept the <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Privacy Policy</a>${ company.footerTermsConditions ? `<span> and the <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>` : "" }`,
        "footer.contact.sendmessage": "Send message",
        "footer.contact.orremember": "Or remember you can also send us a message via",
        "footer.contact.emailbutton": "Email",
        "footer.contact.whatsapp": "Send WhatsApp",
        "footer.contact.visitfacebook": "Visit us on Facebook",
        "footer.contact.successmessage.text1": "Thank you to contact us",
        "footer.contact.successmessage.text2": "Your message has been successfully sent and we´ll reply you as soon as possible.",
        "footer.contact.successmessage.text3": "At {companyName} we work hard to reply our customers messages in a professional way. Thank you for your patience. ",
        "footer.copyright.text1": "Copyright Jorge Alarcón: Filmmaker & Photographer",
        // footer contact form
        "footer.contact.promolima.title": "Reserva tu video promocional a precio promocional",
        "footer.contact.promolima.subtitle": "Completa el siguiente formulario para conseguir tu reserva",
        "footer.contact.promolima.successmessage.title": "Gracias por enviarme tu solicitud",
        "footer.contact.promolima.successmessage.text1": "He recibido tu mensaje y te responderé a la brevedad.",
        "footer.contact.promolima.successmessage.text2": "Estaré organizando mi agenda de trabajo del 2 de Enero al 6 de Marzo del 2020 en Lima, por lo que me pondré en contacto contigo para planificar tu video promocional.",
        "footer.contact.promolima.phone": "Teléfono",
        "footer.contact.promolima.company": "Razón Social (Opcional)",
        "footer.contact.promolima.ruc": "RUC (Opcional)",
        "footer.contact.promolima.message": "Describe brevemente tu proyecto",
        "footer.contact.promolima.send": "Solicitar Reserva",
        // news
        "news.categories": "Categories",
        "news.viewmore": "View more",
        // customs
        "home.cover.text1": "FILMMAKER AND PHOTOGRAPHER in Madrid",
        "home.cover.text2": "The best videos and professional photos for companies, weddings, social networks, advertisements and much more.",
        "home.section1.text1": "Looking for a filmmaker and photographer in Madrid?",
        "home.section1.text2": "I will capture the best shots with creativity, passion and professional quality. I invite you to know some of my services, but do not hesitate to contact me to answer any questions.",
        "home.section1.moreinfo": "More information",
        "home.section1.text3": "Promotional videos, team photos, portraits and corporate videos",
        "home.section1.text4": "COMPANIES & PRODUCTS",
        "home.section1.item2.text1": "WEDDINGS",
        "home.section1.item2.text2": "Videos and photos of the best moments of the wedding, cinematic and unforgettable scenes.",
        "home.section1.item3.text1": "SOCIAL NETWORKS",
        "home.section1.item3.text2": "Looking to stand out in social networks with high impact content from Madrid?",
        "home.section1.item4.text1": "EVENTS",
        "home.section1.item4.text2": "Celebrations, parties, competitions and all kinds of events in Madrid",
        "home.section1.item5.text1": "MUSIC",
        "home.section1.item5.text2": "Video clips, Concerts and shots specially focused on the music industry.",
        "home.section1.item6.text1": "PORTRAITS",
        "home.section1.item6.text2": "Family portraits, professional profiles and business teams",
        "home.section1.item7.text1": "GASTRONOMIC SPACES",
        "home.section1.item7.text2": "Photo and video productions for the gastronomic sector",
        "home.section2.text1": "Recent shots",
        "home.section2.text2": "Some of my shots and set of professional photos for companies, weddings, events, products. <br /> I am in Madrid but traveling is not an obstacle for me.",
        "home.section3.text1": "Video and Photography Offers",
        "home.section3.text2": "Are you looking a very good price for a professional and quality service? Do not hesitate to send me your question. <br /> Below, I have prepared 3 guidance plans, but do not hesitate to contact me to request a custom quote. ",
        "home.pricecard1.text1": "Photography plan",
        "home.pricecard1.text2": "Professional photos in Madrid, of your company, wedding, family, events, friends, for social networks, advertisements, promoting yourself on the Internet or simply for you and your family.",
        "home.pricecard1.text3": "We move our equipment where necessary, sea, air or land",
        "home.pricecard1.text4": "Professional photo session",
        "home.pricecard1.text5": "Editing and materials preparation to publish and share on social networks",
        "home.pricecard2.text1": "Professional Video Plan",
        "home.pricecard2.text2": "Professional shots in Madrid, for your company, wedding, family, events, friends, for social networks, advertisements, promoting your projects on the Internet ... or just for you and your family.",
        "home.pricecard2.text3": "Script design together with the client",
        "home.pricecard2.text4": "Plan for the recording of the shots",
        "home.pricecard2.text5": "We move our equipment where necessary, sea, air or land",
        "home.pricecard2.text6": "Audiovisual postproduction",
        "home.pricecard2.text7": "Editing and preparation of material for publishing and sharing on social networks",
        "home.pricecard3.text1": "Video and Photography Plan",
        "home.pricecard3.text2": "Recording of a professional video in Madrid, of your company, wedding, family, events, friends, for social networks, advertisements, promoted on the Internet ... or just for you and your family.",
        "home.pricecard3.text3": "Professional photo session",
        "home.pricecard3.text4": "Design of the video script together with the client",
        "home.pricecard3.text5": "Plan for the recording of the shots",
        "home.pricecard3.text6": "We move our equipment where necessary, sea, air or land",
        "home.pricecard3.text7": "Audiovisual postproduction",
        "home.pricecard3.text8": "Delivery of videos ready to share",
        "home.pricecard.requestquote": "Request a quote"
    }
};

export const defaultLanguage = "es";
export const availableLanguages = [ "es", "en" ];

// GENERIC
export const getPageByPath = ( path ) => {
    let result = all.find( ( page ) => {
        if ( page.path === path ) {
            return page;
        }
        if ( matchPath( path, page ) ) {
            return page;
        }
        return null;
    } );

    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

export const manager = {};
manager.getRelativeUrl = ( relative ) => `${ company.managerURLRelative }/${ relative }`;

export const text = ( lang, id, replacements ) => {
    let result = "";
    if ( texts[ lang ] && texts[ lang ][ id ] ) {
        result = texts[ lang ][ id ];
    }
    if ( typeof replacements !== "undefined" ) {
        Object.keys( replacements ).forEach( key => {
            result = result.replace( new RegExp( `{${ key }}`, "g" ), replacements[ key ] );
        } );
    }
    return result;
};

export const getLanguageName = ( lang ) => {
    switch ( lang ) {
        case "en":
            return "English";
        case "es":
            return "Español";
        default:
            return "Español";
    }
};

export const getLangByUrlPath = ( path ) => {
    let result = defaultLanguage;
    availableLanguages.forEach( ( lang ) => {
        if ( path.indexOf( `/${ lang }/` ) > -1 ) {
            result = lang;
        }
    } );
    return result;
};

export const contactForms = [
    {
        id: "promolima",
        formName: "Promo Lima",
        relativeUrl: `${ getPage( "news" ).relativeUrl }/promos/promotional-video-lima-2020`,
        titleLanguageKey: "footer.contact.promolima.title",
        subTitleLanguageKey: "footer.contact.promolima.subtitle",
        successTitleLanguageKey: "footer.contact.promolima.successmessage.title",
        successText1LanguageKey: "footer.contact.promolima.successmessage.text1",
        successText2LanguageKey: "footer.contact.promolima.successmessage.text2",
        successSendLanguageKey: "footer.contact.promolima.send",
        emailSubject: "Video Promocional Lima 2020 - Nuevo Contacto Web",
        fields: [
            { id: "contactName", labelLanguageKey: "footer.contact.fullname", mandatory: true },
            { id: "contactEmail", labelLanguageKey: "footer.contact.email", mandatory: true },
            { id: "contactPhone", labelLanguageKey: "footer.contact.promolima.phone", mandatory: true },
            { id: "customCompany", labelLanguageKey: "footer.contact.promolima.company", descriptionLanguageKey: null },
            { id: "customRuc", labelLanguageKey: "footer.contact.promolima.ruc", descriptionLanguageKey: null },
            {
                id: "contactMessage",
                labelLanguageKey: "footer.contact.promolima.message",
                type: "textarea",
                rows: 3,
                mandatory: true
            }
        ]
    }
];

// export const floatingVideos = [
//     {
//         relativeUrl: "/",
//         exactUrl: true,
//         src: "https://www.youtube.com/embed/uKsut6_1LWE",
//         provider: "youtube",
//         sendMessage: true,
//         displayScrollY: 100,
//     },
// ];

export const emailTemplates = {
    theme: {
        logoBackgroundColor: "white",
        logoUrl: `${ company.baseURL }/static/logo-rectangle.png`,
        thanksBackgroundColor: "#F8F8F8",
        buttonBackgroundColor: "#F8F8F8",
        buttonTextColor: "black",
        purchaseDetailsUrl: `${ company.baseURL }/shop/purchase`,
        defaultFont: "Arial",
        defaultFontColor: "#333333",
        purchaseThanksTitle: "Muchas gracias por su compra",
        purchaseThanksSubTitle: "Por favor, conserve la siguiente información.",
        footerText: `Para cualquier información no dude en contactarnos a través de nuestra web ${ company.baseURL }`
    }
};
export const shop = null;
export const gtag = null;
export const floatingVideos = null;
export const floatingActionButtons = null;
