import React from "react";
import Styles from "./styles/Global";
import * as Pages from "../../../utils/Pages";

const PrivacyPolicy = () => (
    <div style={ Styles.GenericPage.SectionCenteredFormalPane }>
        <h3 style={ Styles.GenericPage.SectionTitle }>Protección de datos de carácter personal según la LOPD</h3>
        <p>
            { Pages.company.name }, en aplicación de la normativa vigente en materia de protección de datos de carácter
            personal, informa que los datos personales que se recogen a través de los formularios del sitio web: { Pages.company.domain }, se incluyen en los ficheros automatizados específicos de usuarios de los servicios
            de { Pages.company.name }.
            La recogida y tratamiento automatizado de los datos de carácter personal tiene como finalidad el mantenimiento de la
            relación comercial y el desempeño de tareas de información, formación, asesoramiento y otras actividades propias de { Pages.company.name }.
            Estos datos únicamente serán cedidos a aquellas entidades que sean necesarias con el único objetivo de dar
            cumplimiento a la finalidad anteriormente expuesta.<br />
            { Pages.company.name } adopta las medidas necesarias para garantizar la seguridad, integridad y
            confidencialidad de los datos conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del
            Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos
            personales y a la libre circulación de los mismos.
            El usuario podrá en cualquier momento ejercitar los derechos de acceso, oposición, rectificación y cancelación
            reconocidos en el citado Reglamento (UE). El ejercicio de estos derechos puede realizarlo el propio usuario a través de
            email a: { Pages.company.lopdEmail } { Pages.company.lopdAddress ? `o en la dirección: ${ Pages.company.lopdAddress }` : [] }.<br />
            El usuario manifiesta que todos los datos facilitados por él son ciertos y correctos, y se compromete a mantenerlos
            actualizados, comunicando los cambios a { Pages.company.lopdEmail }.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Datos del responsable del tratamiento:</h4>
        <p>
            <b>Identidad del Responsable:</b> { Pages.company.lopdFullName } { Pages.company.lopdNIF ? <span>- <b>NIF:</b>{ Pages.company.lopdNIF }</span> : null } <br />
            { Pages.company.lopdPostalAddress ? <span><b>Dirección Postal:</b> { Pages.company.lopdPostalAddress } <br /></span> : null }
            <b>Teléfono:</b> { Pages.company.lopdPhone } - <b>Correo electrónico:</b> { Pages.company.lopdEmail } <br />
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Finalidad del tratamiento de los datos personales:</h4>
        <p>
            <strong>¿Con qué finalidad trataremos tus datos personales?</strong><br />
            En { Pages.company.name }, trataremos tus datos personales recabados a través del sitio web: { Pages.company.domain }, con las siguientes finalidades:<br />
            <b>1.</b> En caso de contratación de los bienes y servicios ofertados a través de { Pages.company.domain }, para
            mantener la relación contractual, así como la gestión, administración, información, prestación y mejora del
            servicio.<br />
            <b>2.</b> Envío de información solicitada a través de los formularios dispuestos en { Pages.company.domain }<br />
            <b>3.</b> Remitir boletines (newsletters), así como comunicaciones comerciales de promociones y/o publicidad de { Pages.company.name } y del sector.<br />
            Te recordamos que puedes oponerte al envío de comunicaciones comerciales por cualquier vía y en cualquier momento,
            remitiendo un correo electrónico a la dirección indicada anteriormente.
            Los campos de dichos registros son de cumplimentación obligatoria, siendo imposible realizar las finalidades expresadas si
            no se aportan esos datos.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>¿Por cuánto tiempo se conservan los datos personales recabados?</h4>
        <p>
            Los datos personales proporcionados se conservarán mientras se mantenga la relación comercial o no solicites su
            supresión y durante el plazo por el cuál pudieran derivarse responsabilidades legales por los servicios prestados.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Legitimación:</h4>
        <p>
            El tratamiento de tus datos se realiza con las siguientes bases jurídicas que legitiman el mismo:<br />
            1. La solicitud de información y/o la contratación de los servicios de { Pages.company.name }, cuyos
            términos y condiciones se pondrán a tu disposición en todo caso, de forma previa a una eventual contratación.<br />
            2. El consentimiento libre, específico, informado e inequívoco, en tanto que te informamos poniendo a tu
            disposición la presente política de privacidad, que tras la lectura de la misma, en caso de estar conforme, puedes
            aceptar mediante una declaración o una clara acción afirmativa, como el marcado de una casilla dispuesta al
            efecto.<br />
            En caso de que no nos facilites tus datos o lo hagas de forma errónea o incompleta, no podremos atender tu solicitud,
            resultando del todo imposible proporcionarte la información solicitada o llevar a cabo la contratación de los servicios.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Destinatarios:</h4>
        <p>
            Los datos no se comunicarán a ningún tercero ajeno a { Pages.company.name }, salvo obligación legal.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Datos recopilados por usuarios de los servicios:</h4>
        <p>
            En los casos en que el usuario incluya ficheros con datos de carácter personal en los servidores de alojamiento
            compartido, { Pages.company.name } no se hace responsable del incumplimiento por parte del
            usuario del RGPD.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Retención de datos en conformidad a la LSSI:</h4>
        <p>
            { Pages.company.name } informa de que, como prestador de servicio de alojamiento de datos y en
            virtud de lo establecido en la Ley 34/2002 de 11 de julio de Servicios de la Sociedad de la Información y de Comercio
            Electrónico (LSSI), retiene por un periodo máximo de 12 meses la información imprescindible para identificar el origen de
            los datos alojados y el momento en que se inició la prestación del servicio. La retención de estos datos no afecta al
            secreto de las comunicaciones y sólo podrán ser utilizados en el marco de una investigación criminal o para la
            salvaguardia de la seguridad pública, poniéndose a disposición de los jueces y/o tribunales o del Ministerio que así los
            requiera.
            La comunicación de datos a las Fuerzas y Cuerpos del Estado se hará en virtud a lo dispuesto en la normativa sobre
            protección de datos personales.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Derechos propiedad intelectual { Pages.company.name }:</h4>
        <p>
            { Pages.company.name } es titular de todos los derechos de autor, propiedad intelectual, industrial,
            &quot;know how&quot; y cuantos otros derechos guardan relación con los contenidos del sitio web { Pages.company.domain } y los
            servicios ofertados en el mismo.
            No se permite la reproducción, publicación y/o uso no estrictamente privado de los contenidos, totales o parciales, del
            sitio web { Pages.company.domain } sin el consentimiento previo y por escrito.
        </p>
        <h4 style={ Styles.GenericPage.SectionTitle }>Comunicaciones comerciales:</h4>
        <p>
            En aplicación de la LSSI. { Pages.company.name } no enviará comunicaciones publicitarias o promocionales
            por correo electrónico u otro medio de comunicación electrónica equivalente que previamente no hubieran sido
            solicitadas o expresamente autorizadas por los destinatarios de las mismas.
            En el caso de usuarios con los que exista una relación contractual previa, { Pages.company.name } sí está
            autorizado al envío de comunicaciones comerciales referentes a productos o servicios de { Pages.company.name } que sean similares a los que inicialmente fueron objeto de contratación con el cliente.
            En todo caso, el usuario, tras acreditar su identidad, podrá solicitar que no se le haga llegar más información comercial a
            través de los canales de Atención al Cliente.
        </p>
    </div>
);
export default PrivacyPolicy;
