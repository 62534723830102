export const CommonFonts = {
    brand: "Montserrat",
    title: "Montserrat",
    subTitle: "Roboto",
    button: "Roboto",
    primaryActionButton: "Roboto",
    paragraph: "Roboto"
};

export const CommonColors = {
    primaryBrand: "#FFFFFF",
    textOnPrimaryBrand: "#000000",
    secondaryBrand: "#333",
    success: "#0090FF"
};

export const CommonStyles = {
    MenuItem: {
        fontFamily: CommonFonts.button,
        fontSize: 15,
        padding: 0,
        marginLeft: 10,
        marginRight: 10,
        textDecoration: "none",
        color: "rgba(0,0,0,.5)"
    },
    MenuItemsContainer: {
        margin: 0,
        padding: 0,
        paddingLeft: 20
    },
    SectionTitle: {
        fontFamily: CommonFonts.title,
        padding: 20,
        color: "#000",
        textAlign: "center"
    },
    SectionSubTitle: {
        fontFamily: CommonFonts.subTitle,
        padding: "0 20px 20px 20px",
        fontSize: 20,
        fontWeight: "lighter",
        color: "rgba(0,0,0,.7)",
        textAlign: "center"
    },
    SectionParagraph: {
        fontFamily: CommonFonts.paragraph
    },
    SectionContentContainer: {
        padding: 25,
        textAlign: "center"
    }
};

export default {
    Home: {
        Carousel: {},
        CarouselCaption: {
            top: "1%",
            textAlign: "center",
            justifyContent: "center",
            left: "auto",
            right: "auto",
            margin: "0 8%"
        },
        CarouselCaptionH1: {
            fontFamily: CommonFonts.title,
            fontWeight: "500",
            fontSize: "4vw",
            color: "#000"
        },
        CarouselCaptionH2: {
            fontFamily: CommonFonts.subTitle,
            fontSize: "2.5vw",
            color: "#000",
            margin: "0 auto 4px"
        },
        CarouselCaptionH3: {
            fontFamily: CommonFonts.subTitle,
            fontSize: "2.3vw",
            color: "#FFF",
            backgroundColor: "#000",
            display: "inline",
            padding: "2px 4px",
            marginBottom: 0
        },
        CarouselCaptionH4: {
            fontFamily: CommonFonts.subTitle,
            fontSize: "2vw",
            color: "#333",
            margin: 1
        },
        CarouselSecondButton: {
            marginLeft: 10
        },
        CarouselPrimaryButton: {
            fontFamily: CommonFonts.primaryActionButton,
            margin: "0",
            backgroundColor: "#FFC94B",
            border: "4px #000 solid",
            color: "#000",
            selfAlign: "center"
        },
        CarouselPrimaryButtonIcon: {
            marginBottom: -5
        },
        cardGroup: {
            justifyContent: "center",
            margin: 25,
            fontFamily: CommonFonts.paragraph
        },
        InlineFacebookIcon: {
            display: "inline",
            width: "1.2rem",
            height: "1.2rem",
            marginBottom: 2,
            marginRight: 4
        },
        CardAddressText: {
            color: "#3689ee",
            fontWeight: "bold"
        },
        CardLink: {
            color: "#3689ee",
            padding: 0,
            margin: 0,
            textDecoration: "none"
        },
        SectionTitle: { ...CommonStyles.SectionTitle },
        SectionSubTitle: { ...CommonStyles.SectionSubTitle },
        CardFooter: {
            background: "none",
            border: "none"
        },
        SectionContentContainer: { ...CommonStyles.SectionContentContainer },
        CarouselJobs: {
            height: "44%",
            textAlign: "left",
            justifyContent: "center",
            backgroundColor: "rgba(255,255,255,0.6)",
            borderRadius: 5,
            padding: "1vw"
        },
        CarouselJobsItem: {
            position: "relative",
            display: "inline-block",
            marginLeft: 10,
            height: "100%"
        },
        CarouselJobsFirstItem: {
            height: "100%",
            margin: "auto",
            padding: 0
        },
        CarouselJobjsImg: {
            height: "100%",
            width: "auto",
            float: "left",
            marginRight: 10
        },
        CarouselJobsItemDetails: {
            fontSize: "1.5vw",
            color: "#333"
        },
        CarouselJobsItemDetailsHighlightedText: {
            fontSize: "1.5vw",
            color: "#000",
            fontWeight: "bold"
        },
        CarouselSocialIcon: {
            width: "2.5vw",
            minWidth: "40px",
            margin: "1vw 1.5vw 1vw 0"
        },
        poseButtonContainer: {
            display: "inline-block",
            marginTop: "1vw"
        },
        priceCards: {
            // container
        },
        priceCardTitle: {
            fontFamily: CommonFonts.title,
            fontSize: 26
        },
        priceCard: {
            textAlign: "center",
            borderColor: CommonColors.textOnPrimaryBrand
        },
        priceCardPriceCheck: {
            color: "#fc0f34"
        },
        priceCardPrice: {
            fontFamily: CommonFonts.title,
            textAlign: "center"
        },
        priceCardFooter: {
            backgroundColor: CommonColors.primaryBrand,
            color: CommonColors.textOnPrimaryBrand
        },
        CardDeck: {
            padding: "8px"
        },
        ImgOverlay: {
            background: "rgba(0,0,0,0.7)",
            position: "absolute",
            top: "10%",
            height: "80%",
            left: "6%",
            width: "88%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            padding: "20px"
        },
        ImgOverlayTitle: {
            fontFamily: "Montserrat",
            textAlign: "center",
            fontSize: "2vw",
            color: "white",
            width: "100%"
        },
        ImgOverlayTitlePortrait: {
            fontFamily: "Montserrat",
            textAlign: "center",
            fontSize: "4.5vw",
            color: "white",
            width: "100%"
        },
        ImgOverlayText: {
            fontFamily: "Roboto",
            textAlign: "center",
            fontSize: "1.6vw",
            fontWeight: "100",
            color: "white",
            width: "100%"
        },
        ImgOverlayTextPortrait: {
            fontFamily: "Roboto",
            textAlign: "center",
            fontSize: "4vw",
            fontWeight: "300",
            color: "white",
            width: "100%"
        },
        ImgOverlayButton: {
            fontFamily: "Roboto",
            fontSize: "1.5vw",
            fontWeight: "100"
        },
        ImgOverlayButtonPortrait: {
            fontFamily: "Roboto",
            fontSize: "3.6vw",
            fontWeight: "100"
        }
    },
    GenericPage: {
        SectionTitle: { ...CommonStyles.SectionTitle },
        SectionSubTitle: { ...CommonStyles.SectionSubTitle },
        SectionContentContainer: { ...CommonStyles.SectionSubTitle },
        SectionParagraph: { ...CommonStyles.SectionParagraph },
        ImageCircle: { margin: 10 },
        SectionCenteredFormalPane: {
            textAlign: "left",
            maxWidth: "1000px",
            margin: "auto"
        }
    },
    CookiesAcceptance: {
        AcceptCookies: {
            position: "fixed",
            bottom: "0",
            left: "0",
            width: "100%",
            margin: 0,
            fontSize: "12px",
            color: "#FFF",
            zIndex: 3,
            backgroundColor: "rgba(0,0,0,0.8)",
            padding: "10px 10px 15px 10px"
        },
        Link: { color: "#FFF", textDecoration: "underline" }
    },
    Footer: {
        FooterContainer: {
            paddingTop: 50,
            paddingHorizontal: 50,
            paddingBottom: 10,
            fontFamily: CommonFonts.paragraph,
            backgroundColor: "#f8f9fa"
        },
        MiddleColumn: {
            textAlign: "center"
        },
        MenuItem: {
            fontFamily: CommonFonts.button,
            fontSize: 18,
            lineHeight: "34px",
            paddingBottom: 0,
            textDecoration: "none",
            color: "rgba(0,0,0,.5)"
        },
        MenuItemsContainer: {
            listStyle: "none",
            margin: 0,
            padding: 0
        },
        SectionTitle: {
            fontFamily: CommonFonts.title,
            textAlign: "center",
            paddingTop: 40,
            paddingHorizontal: 20,
            color: "rgba(0,0,0,.7)"
        },
        SectionSubTitle: {
            fontFamily: CommonFonts.title,
            textAlign: "center",
            paddingTop: 4,
            paddingHorizontal: 40,
            marginBottom: 40,
            fontSize: 20,
            fontWeight: "lighter",
            color: "rgba(0,0,0,.5)"
        },
        CopyrightFooter: {
            marginTop: 20,
            textAlign: "center",
            padding: 20,
            fontSize: 14,
            color: "rgba(0,0,0,.6)"
        },
        footerLink: {
            color: "rgba(0,0,0,.8)",
            textDecoration: "none"
        },
        ActionButtonsContainer: {
            marginBottom: 40,
            justifyContent: "center"
        },
        ActionButton: {
            margin: 10
        },
        ActionButtonIcon: {
            marginRight: 10
        },
        ContactFormContainer: {
            maxWidth: "600px",
            width: "90%",
            margin: "auto"
        },
        SuccessMessageAlert: {
            maxWidth: "700px",
            width: "90%",
            margin: "auto"
        }
    },
    NavBar: {
        ...CommonStyles,
        NavbarContainer: {
            backgroundColor: "rgba(255,255,255,0.6)",
            padding: 4,
            borderBottom: "1px #cccbcb solid",
            position: "relative",
            left: "0%",
            top: "0%",
            zIndex: 5
        },
        NavbarContainerPortrait: {
            backgroundColor: "rgba(255,255,255,0.6)",
            padding: 4,
            borderBottom: "1px #cccbcb solid",
            position: "static",
            left: "0%",
            top: "0%",
            zIndex: 5
        },
        Navbar: {},
        NavbarBrand: {
            fontFamily: CommonFonts.brand,
            fontSize: 24,
            fontWeight: "bold",
            lineHeight: "32px"
        },
        ResponsiveMenuToggle: {
            border: 0,
            fontSize: "2.5rem",
            padding: "4px 10px",
            marginTop: "4px",
            color: "#333333"
        },
        NavbarBrandTitle: {
            fontFamily: CommonFonts.brand,
            color: "#333",
            fontWeight: 200,
            lineHeight: "20px",
            verticalAlign: "middle",
            fontSize: "16px"
        }
    }
};
